import { FaCode, FaMobileAlt, FaCloud,   FaLink, } from 'react-icons/fa';
import { BiPalette } from 'react-icons/bi';
import { FaCogs } from 'react-icons/fa';
import person from '../assets/person_5.webp';
import banking from '../assets/banking.webp'
import cmo from '../assets/cmo.webp'
import education from '../assets/education.webp'
import ticket from '../assets/ticket.webp'
import commerce from '../assets/commerce.webp'
import hospitality from '../assets/hospitality.webp'
import insurance from '../assets/insurance.webp'
import healthcare from '../assets/medical.webp'

export const services = [
    {
        logo: <FaCode size={45} />,
        title: 'Web Development',
        description: [
            ' Unleash your digital presence with captivating websites that engage users and drive conversions.'
        ]
    },
    {
        logo: <FaMobileAlt size={45} />,
        title: ' App Development',
        description: [
            ' Empower your business with cutting-edge mobile apps that captivate audiences and enhance user experiences.'
        ]
    },
    {
        logo: <FaCloud size={45} />,
        title: 'Cloud Solutions',
        description: [
            ' Architect secure and scalable cloud infrastructures for seamless data management and enhanced collaboration.'
        ]
    },
    {
        logo: <BiPalette size={45} />,
        title: 'Branding & Design',
        description: [
            'Make your brand stand out with exceptional visuals and immersive experiences that connect with your audience.'
        ]
    },
    {
        logo: <FaLink size={45} />,
        title: 'Blockchain Development',
        description: [
            'Revolutionize industries with scalable and transparent blockchain solutions that empower trust.'
        ]
    },
    {
        logo: <FaCogs size={45} />,
        title: 'IT Consulting & Support',
        description: [
            'Unlock business potential with strategic IT consulting services that optimize infrastructure and drive digital transformation.'
        ]
    }
];

export const ClientsProjects = [
    {
        title: 'Education',
        description: 'We specialize in revolutionizing education through innovative EdTech solutions, comprehensive e-learning platforms, and robust management systems. Our transformative technologies enhance student experiences, streamline administrative processes, and embrace the future of education.',
        image: education
    },
    {
        title: 'Commerce',
        description: 'Our cutting-edge solutions empower businesses in the commerce industry with seamless transactions, efficient inventory management, and exceptional customer engagement. From online stores to physical retail, we drive success in the digital marketplace, helping businesses thrive in a competitive landscape.',

        image: commerce
    },
    {
        title: 'Hospitality',
        description: 'Elevate your hospitality business with our tailored solutions. Our hotel management software, reservation systems, and guest engagement platforms empower you to provide exceptional experiences, streamline operations, and exceed guest expectations. Experience the future of hospitality with our innovative solutions.',
        image: hospitality
    },
    {
        title: 'Insurance',
        description: 'Unlock the potential of your insurance business with our transformative solutions. From advanced policy management systems to claims processing and customer engagement platforms, our comprehensive solutions empower insurers to optimize operations, enhance customer experiences, and stay ahead in a rapidly evolving industry.',
        image: insurance
    },
    {
        title: 'CRM & BMS',
        description: 'Streamline your operations and nurture customer relationships with our integrated CRM and BMS solutions. Our comprehensive platforms cover lead management, sales automation, project tracking, and more. With centralized data and seamless collaboration, businesses can optimize efficiency, boost productivity, and drive growth.',
        image: cmo
    },
    {
        title: 'Banking',
        description: 'Experience the future of banking with our advanced solutions. From mobile banking apps to fraud detection systems and financial management tools, we empower banks to deliver digital experiences, enhance efficiency, and build customer trust. Revolutionize banking and stay ahead in the evolving financial landscape with our innovative solutions.',
        image: banking
    },
    {
        title: 'Ticketing',
        description: 'Simplify ticketing processes with our seamless solutions. From events to attractions and customer support, our innovative ticketing platforms offer easy booking, efficient ticket management, and exceptional customer experiences. Enhance your ticketing operations and ensure smooth entry for your attendees with our hassle-free solutions.',
        image: ticket
    },
    {
        title: 'Healthcare',
        description: ' We specialize in transforming healthcare through innovative solutions. From medical record systems to telehealth platforms and patient engagement tools, we empower providers to deliver exceptional care, streamline operations, and embrace the future of healthcare. Improve outcomes with our cutting-edge solutions.',
        image: healthcare
    },

];

export const teamMembers = [

    {
        name: 'Elie Hannouch',
        title: 'CEO & Founder',
        description: 'Master Architect of Cutting-Edge Concepts, Pioneering Technological Marvels, and Enchanting Customers with Sensible Technology!',
        image: 'https://i.ibb.co/kK8qdNY/Whats-App-Image-2023-09-09-at-5-07-45-PM.jpg' || person,
        linkedin: 'https://www.linkedin.com/in/elie-hannouch/'
    },
    {
        name: 'Lynn Bou Ghannam',
        title: 'Full Stack Engineer',
        description: 'With a passion for coding and an eye for detail, I specialize in creating seamless connections between users and technology.',
        image: 'https://i.ibb.co/LpSJpVC/11.jpg' || person,
        linkedin: 'https://www.linkedin.com/in/lynn-bou-ghannam-3a7376208/'
    },
    
    {
        name: 'Abdul Rahman Masri Attal',
        title: 'Backend & Databases Specialist',
        description: "Passionate about creating innovative programs that drive organizational success through improved efficiency and effectiveness.",
        image: 'https://i.ibb.co/7XwzwBB/IMG-2669.jpg' || person,
        linkedin: 'http://linkedin.com/in/abed-attal'
    },
    
    {
        name: 'Ralph Antoun',
        title: 'Full Stack Engineer',
        description: "Empowering digital solutions with a full stack developer's expertise. Bridging front-end elegance with back-end functionality for seamless experiences.",
        image: 'https://avatars.githubusercontent.com/u/99551473?v=4' || person,
        linkedin: 'https://www.linkedin.com/in/ralph-antoun/'
    },
    {
        name: 'Mustafa Khodor',
        title: 'Full Stack Engineer',
        description: "A versatile full stack developer adept at driving digital transformation. Seamlessly blending front-end finesse with back-end robustness to deliver cutting-edge solutions.",
        image: 'https://i.ibb.co/1nz9XKC/13.jpg' || person,
        linkedin: 'https://www.linkedin.com/in/mustafa-khodor/'
    },
    {
        name: 'Lynn Haydar',
        title: 'Full Stack Engineer',
        description: "An accomplished full stack developer orchestrating the harmony of technology stacks. Empowering businesses with innovative, scalable, and secure applications.",
        image: 'https://i.ibb.co/W2BQ2QN/Whats-App-Image-2023-04-21-at-2-24-57-PM-1.jpg' || person,
        linkedin: 'https://www.linkedin.com/in/lynn-haidar-a58745258/'
    },
    {
        name: 'Ahmad Shakik',
        title: 'Full Stack Engineer',
        description: "A full stack developer with a passion for delivering excellence. From conceptualization to deployment, crafting end-to-end solutions that elevate businesses to new heights.",
        image: 'https://i.ibb.co/TK0cXLb/shakik.jpg' || person,
        linkedin: 'https://www.linkedin.com/in/ahmad-shakik/'
    },

];

export const TwoOfUs = [
    {
        title: 'Our Mission',
        description: 'At  Two Of Us, we are on a mission to revolutionize the digital landscape through innovative IT solutions. With a passion for excellence and cutting-edge technologies, we collaborate closely with our clients to understand their unique needs. Our commitment is to empower businesses, streamline operations, and create transformative experiences, paving the way for success in the digital age.'
    },
    {
        title: 'Our Team',
        description: 'Our dynamic team is united by a shared mission to redefine IT solutions. With diverse expertise and a relentless pursuit of excellence, we collaborate seamlessly to deliver exceptional outcomes. Together, we push boundaries, solve complex challenges, and transform possibilities into realities. Our passion, innovation, and customer-centric approach empower businesses to thrive in the digital realm.'
    },

    {
        title: 'Our Values',
        description: ' Our success is built upon a foundation of values. Integrity guides every decision we make, ensuring transparency, trust, and ethical practices. Innovation fuels us to embrace emerging technologies and creative solutions. Collaboration fosters teamwork, open communication, respect, and shared knowledge. Customer focus drives us to exceed expectations and deliver exceptional experiences.'
    },


];
