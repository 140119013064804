import React from "react";
import "./partners.css";
import mongo from "../../assets/MongoDB_White.svg";
import { Link } from "react-router-dom";
const PartnersSection = () => {
  return (
    <div className="partners-container">
      
      <div className="partners-wrapper">
        <div className="partners-text">
          <h1>We Work with the Best Partners </h1>
        </div>
        <div className="partners-desc">
          <p>
            In our commitment to excellence, we've forged alliances with
            industry leaders. Together, we ensure quality, innovation, and
            seamless experiences for our clients. Our partners reflect our
            values and drive to exceed expectations at every turn.
          </p>
        </div>
        <div className="partner-list">
          <Link to="https://www.mongodb.com/" target="_top">
            <div className="partner">
              <img src={mongo} alt="Partner 1" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PartnersSection;
