import React, { useEffect } from 'react'
 
import HeroContact from '../../components/hero-contact/HeroContact'
import ContactForm from '../../components/contact-form/ContactForm'
import { useLocation } from 'react-router-dom'


const ContactUs = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <div>
      <HeroContact/>
      <ContactForm/>
    </div>
  )
}

export default ContactUs
