import React, { useEffect, useState } from 'react';
import './navbar.css';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/logoNav.svg';
import { FaTimes } from 'react-icons/fa';
import { FiMenu } from "react-icons/fi";


const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isActiveLink = (pathname) => {
    return pathname === location.pathname ? 'active' : '';
  };

  const getNavbarClass = () => {
    let navbarClass = 'newHeader';
    if (isScrolled) {
      navbarClass += ' scrolled';
    }
    if (location.pathname === '/contact-us') {
      navbarClass += ' scrolled2';
    }
    return navbarClass;
  };
  useEffect(() => {
    setShowMenu(false);
  }, [location]);


  const Drawer = ({ showMenu, setShowMenu }) => {
    return (
      <ul className={` drawer ${showMenu ? 'open' : ''}`}>
        <li>
          <FaTimes color='#edb71e' className='times' size={30} onClick={() => setShowMenu(!showMenu)} />
        </li>
        <li>
          <Link to={'/'} style={{ textDecoration: 'none', color: '#edb71e' }}>
            Home
          </Link>
        </li>
        <li>
          <Link to={'/about-us'} style={{ textDecoration: 'none', color: '#edb71e' }}>
            About Us
          </Link>
        </li>
        <li>
          <Link to={'/contact-us'} style={{ textDecoration: 'none', color: '#edb71e' }}>
            Contact Us
          </Link>
        </li>
      </ul>

    );
  };

  return (
    <>
      <header className={getNavbarClass()}>
        <Link to="/" className="newLogo" style={{ textDecoration: 'none', color: 'white' }}  >
          <div className="logo">
            <img src={logo} alt="logo" height={30} width={130} />
          </div>
        </Link>
        <FiMenu size={30} className='bars' onClick={() => setShowMenu(!showMenu)} />
        <ul className="newNavbar">
          <li>
            <Link to="/" className={isActiveLink('/')} style={{ textDecoration: 'none' }}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about-us" className={isActiveLink('/about-us')} style={{ textDecoration: 'none' }}>
              About Us
            </Link>
          </li>
          <li>
            <Link to="/contact-us" className={isActiveLink('/contact-us')} style={{ textDecoration: 'none' }}>
              Contact Us
            </Link>
          </li>
        </ul>

      </header>
      <Drawer showMenu={showMenu} setShowMenu={setShowMenu} />
    </>
  );
};

export default Navbar;
