import React, { useEffect } from 'react'
import { AboutHero } from '../../components'
import AboutTheCompany from '../../components/aboutTheTeam/AboutTheCompany'
import TeamSection from '../../components/teamSection/TeamSection'
import { useLocation } from 'react-router-dom'
const About = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  

  return (
    <div>
      <AboutHero />
      <AboutTheCompany/>
      {/* <TeamSection/> */}

    </div>
  )
}

export default About
