import React from 'react'
import './about.css'
import { useSpring, animated } from 'react-spring';
import { FaHeart } from 'react-icons/fa';


function Number({ n }) {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 200,
    config: { mass: 1, tension: 80, friction: 20 },
  });
  return <animated.div>{number.to(n => n.toFixed(0))}</animated.div>;
}


const AboutHero = () => {
  return (
    <div className='about-container'>

      <div className="about-sub">

        <div className='about-description'>
          <div className='about-title'>
            <span >We <FaHeart size={25} className='heart' /> What We Do</span>
          </div>
          <div className='desc1'>
            Unleash the Potential of Your Business with Cutting-Edge Technology Solutions! At Two Of Us L.L.C, we are dedicated to empowering businesses like yours by offering an all-encompassing suite of transformative solutions. Our mission? To turn your challenges into exciting growth opportunities!
          </div>
          <div className='desc2'>

            From strategic planning to flawless execution, our team of seasoned experts is committed to your success. Join forces with us and let's embark on a journey of digital transformation, revolutionizing your business for the modern age. Together, we'll soar to new heights of innovation and prosperity!
          </div>
        </div>
        <div className='stats-container'>
          <div className='stats'>
            <div className='stat'>
              <h3><Number n={5} />+</h3>
              <p>Years of  Experience</p>
            </div>
            <div className='stat'>
              <h3><Number n={10} />+</h3>
              <p>Projects  Completed</p>
            </div>
            <div className='stat'>
              <h3><Number n={20} /> +</h3>
              <p>Happy   Clients</p>
            </div>

          </div>
        </div>
      </div>



    </div>
  )
}

export default AboutHero
