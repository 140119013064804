import React from 'react'
import './project.css'
import { FaLightbulb } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Project = () => {
  return (
    <div className='project-container'>
      <div className='project-phrase'>
        <p>
         Have a Project In Mind? <FaLightbulb className='project-icon'  />
        </p>
        <button>
          <Link to='/contact-us' style={{color:'black',textDecoration:'none'}}>
          Contact Us
          </Link>
        </button>
      </div>

    </div>
  )
}

export default Project