import React from 'react';
import './hero.css';

import elie from '../../assets/elie.svg';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <>
      <div className='hero'>
        <div className="sub-hero">
          <div className="hero-text">
            <span>
              Where Technology <br />Makes Sense !
            </span>
            <p>
              Two Of Us empower the future through innovative technology solutions.
            </p>
            <div className="button-container">
              <Link to='/about-us' style={{ textDecoration: 'none' }} >
                <button className="button-1">
                  About Us
                </button>
              </Link>
              <Link to='/contact-us' style={{ color: 'black', textDecoration: 'none' }} >
                <button className="button-1">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <div className='image-container'>
            <img src={elie} alt="" width={500} height={500} className="floating-image" />
          </div>
        </div>
        <div className='arrow-down'>
          <a href="#services" style={{ color: 'black' }}>
            <div>
              <span>&#8595;</span>
            </div>
          </a>
        </div>
      </div>
    </>
  )
}

export default Hero;
