import React from 'react';
import Slider from 'react-slick';
import './client.css';
import ClientCard from './client/ClientCard';
import { ClientsProjects } from '../../store/content';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';


const Clients = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1254,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 875,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="clientMainDiv">
      <div className="clients-title">
        <h1>
          Industries We're Working In
        </h1>
      </div>
      <div className="ClientSection">
        <Slider {...settings} className="myClientSlider">
          {ClientsProjects.map((client, index) => (
            <div key={index} className="clients-container" tabIndex={index}>
              <ClientCard client={client} />
            </div>
          ))}
        
        </Slider>
      </div>
    </div>
  );
};

const CustomPrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={`${className} custom-arrow prev`} onClick={onClick}>
      <FiChevronLeft color="white" />
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={`${className} custom-arrow next`} onClick={onClick}>
      <FiChevronRight color="white" />
    </div>
  );
};

export default Clients;
