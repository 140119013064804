import React from 'react'
import './heroContact.css'
import contact from '../../assets/contact.webp'
const HeroContact = () => {
    return (
        <div className='heroContact-container'>
            <div className='hero-contact-sub'>
                <div className="hero-contact-title">
                    <h1>
                        Let's Chat Technology, Discover Possibilities, and Make Magic Happen Together !</h1>
                </div>
                <div className='contact-img'>
                    <img src={contact} alt="" />
                </div>
            </div>
            <a href="#form">
                <div className='contact-arrow'>
                    <span>
                        &#8595;
                    </span>
                </div>

            </a>

        </div>
    )
}

export default HeroContact