import React from 'react';
import './services.css';
import ServiceCard from './service/ServiceCard';
import { services } from '../../store/content'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Services = () => {


  return (
    <>
    
      <div className='services-container' id='services'>
        <div className='services-title'>
          <h1>Services We Offer</h1>
        </div>
        <div className='services-cards'>
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              logo={service.logo}
              title={service.title}
              description={service.description}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Services;
