import React, { useEffect } from 'react'
 
import { Clients, Hero, PartnersSection, Project, Services } from '../../components';
import { useLocation } from 'react-router-dom';
const Home = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <div className='homeContainer'>
      <Hero />
      <Services />
      <Clients />
      <PartnersSection />
      <Project />
    </div>
  )
}

export default Home
