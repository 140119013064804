import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import { notification, Spin } from 'antd';
import './contactForm.css';
import { services } from '../../store/content';
import { FaEnvelope, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';

const ContactForm = () => {
  const [selectedServices, setSelectedServices] = useState([]);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [organization, setOrganization] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);






  const handleServiceChange = (serviceTitle) => {
    if (selectedServices.includes(serviceTitle)) {
      setSelectedServices(selectedServices.filter((service) => service !== serviceTitle));
    } else {
      setSelectedServices([...selectedServices, serviceTitle]);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();


    setLoading(true);

    //const selectedServicesString = selectedServices.join(', ');

    const formData = {
      fullName: fullName,
      email: email,
      phoneNumber: phoneNumber,
      company: organization,
      message: message,
      services: selectedServices
    };

    try {
      const response = await fetch('https://eu-west-1.aws.data.mongodb-api.com/app/twoofus_contactus-spfps/endpoint/contactus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        notification.success({
          message: 'Thank you for your interest!',
          description:
            'Our dedicated team eagerly awaits your connection. Anticipate a swift response as we embark on a journey towards success.',
          className: 'custom-notification'
        });
        window.scrollTo(0, 0);
        setFullName('');
        setEmail('');
        setPhoneNumber('');
        setOrganization('');
        setMessage('');
        setSelectedServices([]);

      } else {
        console.error('Form submission failed.');
      }
    } catch (error) {
      console.error('An error occurred during form submission:', error);
    } finally {
      setLoading(false);

    }
  };




  return (
    <div className="contact-form">
      <div className="form-title">
        <p>Our team loves to answer your questions!</p>
      </div>
      <div className="form-desc"></div>
      <form onSubmit={handleSubmit} autoComplete={false}>
        <div className="contact-info">
          <a href="mailto:info@twoofus.io" className='contact'>
            <div id="form">
              <FaEnvelope size={30} />
              <label htmlFor="">Email</label>
              <p>info@twoofus.io</p>
            </div>
          </a>
          <a href="tel:+96181792967" className='contact'>
            <div >
              <FaPhone size={30} />
              <label htmlFor="">Phone</label>
              <p>+961 81 792 967</p>
            </div>
          </a>
          <span  className='contact'>
            <div >
              <FaMapMarkerAlt size={30} />
              <label htmlFor="">Address</label>
              <p>Byblos, Lebanon</p>
            </div>
          </span>
        </div>

        <div className="form-group">
          <div className="form-container">
            <div className="input-container">
              <label htmlFor="">Full Name</label>
              <input
                required
                type="text"
                value={fullName}
                onChange={(e) => setFullName(DOMPurify.sanitize(e.target.value))}
              />
            </div>
            <div className="input-container">
              <label htmlFor="">Email</label>
              <input
                required
                type="email"
                value={email}
                onChange={(e) => setEmail(DOMPurify.sanitize(e.target.value))}
              />
            </div>
            <div className="input-container">
              <label htmlFor="">Phone Number</label>
              <input
                required
                minLength={8}
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(DOMPurify.sanitize(e.target.value))}
              />
            </div>
            <div className="input-container">
              <label htmlFor="">Organization/Company</label>
              <input
                required
                type="text"
                value={organization}
                onChange={(e) => setOrganization(DOMPurify.sanitize(e.target.value))}
              />
            </div>
            <div className="input-container">
              <label htmlFor="services">Services</label>
              <div className="services-container">
                {services.map((service, index) => (
                  <React.Fragment key={index}>
                    <input

                      type="checkbox"
                      id={`checkbox${index}`}
                      checked={selectedServices.includes(service.title)}
                      onChange={() => handleServiceChange(service.title)}
                    />
                    <label htmlFor={`checkbox${index}`}>{service.title}</label>
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="input-container">
              <label htmlFor="">Message</label>
              <textarea
                required
                value={message}
                onChange={(e) => setMessage(DOMPurify.sanitize(e.target.value))}
                cols="30"
                rows="5"
              ></textarea>
            </div>
            <div className="form-button-container">
              <button type="submit" disabled={loading}>
                {loading ? <Spin /> : 'Let\'s Chat'}
              </button>
            </div>

          </div>
          <div className="line"></div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
