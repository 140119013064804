import './App.css';
import { Routes, Route } from 'react-router-dom';

import { Home, About, ContactUs } from './screens'
import {Footer, Navbar, NotFound,  } from './components';
  
const routes = [
  {
    path: '/',
    element: <Home />,

  },
  {
    path: '/about-us',
    element: <About />,
  },
  {
    path: '/contact-us',
    element: <ContactUs />,
  },
 {
    path: '*',
    element: <NotFound />,
 }
  

];


function App() {


  return (
    <>
      <Navbar />
      <Routes>
        {
          routes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))
        }
         
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
