import React from 'react';
import '../client.css';
 

const ClientCard = ( {client} ) => {
  
  return (
    <div className='client-card'>
      <div className='client-proj-image'>
        <img src={client.image} alt='client-logo' />
      </div>
      <div className="client-description">
        <div className='project-title'>
          <h2>{client.title}</h2>
        </div>
        <div className='project-desc'>
          <p>{client.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ClientCard;
