import React from "react";

import { Link } from "react-router-dom";
import {
  RiInstagramLine,
  RiLinkedinLine,
  RiWhatsappLine,
} from "react-icons/ri";
import "./footer.css";
function Footer() {
  return (
    <footer className="heroF">
      <div className="container flex">
        <div className="container__about">
          <h3>Two Of Us</h3>
          <p>
            Empowering the future through innovative technology solutions.
          </p>
        </div>
        <div className="container-pages flex">
          <div className="container__recentpages">
            <h3 style={{ color: "white" }}> Pages</h3>
            <ul>
              <li>
                <Link to={'/'}>
                  Home
                </Link>
              </li>
              <li>
                <Link to={'/about-us'}>
                  About us
                </Link>
              </li>
              <li>
                <Link to={'/contact-us'}>
                  Contact us
                </Link>
              </li>

            </ul>
          </div>
        </div>
      </div>
      <div className="line__separete"></div>
      <div className="by flex">
        <p style={{width:'100%'}}>
          Copyright ©️ {new Date().getFullYear()} All Rights Reserved by Two of Us.
        </p>
        <div className="icons">
          <Link to="https://instagram.com/twoofus_llc?igshid=OGQ5ZDc2ODk2ZA==" className="icon1 icon--instagram">
            <RiInstagramLine />
          </Link>

          <Link to="https://www.linkedin.com/company/two-of-us-cooperation/" className="icon1 icon--linkedin">
            <RiLinkedinLine />
          </Link>
          <Link to={'https://api.whatsapp.com/send?phone=71838034'} className="icon1 icon--whatsapp">
            <RiWhatsappLine   />
          </Link>

        </div>
      </div>
    </footer>
  );
}

export default Footer;
