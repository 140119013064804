import React from 'react';
import { Link } from 'react-router-dom';
import './notFound.css';

const NotFound = () => {
  return (
    <div className="not-found">
      <h1 className="not-found__title">Oops! Page Not Found</h1>
      <p className="not-found__message">
        Looks like we took a wrong turn. But fear not, we're still together on this digital journey.
        Let's find our way back home!
      </p>
      <Link to="/" className="not-found__button">Return Back Home</Link>
    </div>
  );
};

export default NotFound;
