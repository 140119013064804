import React from 'react';
import './aboutTheCompany.css';
import { TwoOfUs } from '../../store/content'


const AboutTheCompany = () => {


    return (
        <>
            <section className="about-sectionD">
                <div className="about-containerD">
                    <div className="card-containerD">
                        {
                            TwoOfUs.map((item, key) => (
                                <>
                                    <div className="cardD" key={key}>
                                        <h3 className="card-titleD" >{item.title}</h3>
                                        <p className="card-descriptionD">
                                            {item.description}
                                        </p>
                                    </div>
                                </>
                            ))
                        }

                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutTheCompany;
