import React from 'react';

import '../services.css'


const ServiceCard = (props) => {
    const { logo, title, description } = props;


    return (
        <div className='card-container'  >
            <div className="card-title">
                <div className='card-logo'>
                    {logo}

                </div>
                <p>

                    {title}
                </p>
            </div>
            <div className="card-desc">
                <p>
                    {description}
                </p>
            </div>
        </div>
    )
}

export default ServiceCard;
